<template>
<div>
  <el-table :data="classHome" height="400">
      <el-table-column prop="id" label="部门id">
      </el-table-column>
      <el-table-column prop="branchname" label="部门名称"></el-table-column>
  </el-table>
</div>
  <div class="mt-3">
    <el-form
      label-width="6rem"
      :model="Addbranch"
      style="
        max-width: 100rem;
        height: 180px;
        background-color: LightCyan;
        border-radius: 5%;
      "
      class="mx-auto my-auto mt-3"
    >
      <el-form-item> </el-form-item>
      <el-form-item label="新增部门名称:" label-width="7rem">
        <el-input v-model="Addbranch.branchname" style="width: 200px" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" class="mx-5" @click="addbranch"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "AddrenwuFrame",
  components: {},
  data() {
    return {
      classHome: [],
      Addbranch: {
        branchname: null,
        rol:"1"
      },
    };
  },
  methods: {
    addbranch() {
      console.log(this.Addbranch);
      this.$axios
        .get("/user/insertbranch?branchname=" + this.Addbranch.branchname+"&rol="+this.Addbranch.rol)
        .then((response) => {
          let data = response.data;
          if (data.code == 200) {
            this.$router.go(0)
          } else {
            alert(data.msg);
          }
        });
    },
    classify() {
      this.$axios.get("/user/branchlist").then((response) => {
        let data = response.data;
        if (data.code == 200) {
          this.classHome = data.data;
        } else {
          alert(data.msg);
        }
      });
    },
  },
  mounted() {
    this.classify();
  },
};
</script>

<style scoped>
.headImg {
  width: 5rem;
  height: 5rem;
  border-radius: 10%;
}
</style>