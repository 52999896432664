<!--template都是写的vue组件，它自动根据export default导出-->
<template>
  <router-view></router-view>
</template>

<script>


  //设置当前模板的信息
export default {
  name:'App',
  
    
  }
  

  
</script>

<style>

</style>

