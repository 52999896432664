//Vue.createApp(vueConfig)
import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus' //引入element-plus的js
import 'element-plus/dist/index.css' //引入element-plus的css
import 'bootstrap/dist/css/bootstrap.css' //引入bootstrap的css
import router from '@/tools/router.js'
import { axiosInstance } from '@/tools/axios-http.js'
import "@/assets/comment/font.css"
//import 'lib-flexible'

//初始化vue，createApp()等价于Vue.createApp()
//mount("#app"),vue决定挂载的html标签为app,默认指定
const app = createApp(App);
app.config.globalProperties.$axios = axiosInstance //把axios绑定
app.use(ElementPlus) //注册第三方软件库到vue实例中
app.use(router) //应用路由规则
app.mount('#app')