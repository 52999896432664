//1、导入vue-router的函数
import { createRouter, createWebHashHistory } from 'vue-router'

//2、导入需要跳转的页面组件

//import LoginFrame from '@/views/Login/LoginFrame'
import SpaceFrame from '@/views/Space/SpaceFrame'
import BranchFrame from '@/views/Space/admin/BranchFrame'
import UserFrame from '@/views/Space/admin/UserFrame'
import UpdateuserFrame from '@/views/Space/admin/UpdateuserFrame'

//3.使用一个数组，定义路由规则
//每个路由都需要映射到一个组件
const routes = [
    //  { path: '/', component: LoginFrame, hidden: true, }, //跳转'/login'时展示LoginFrame组件
    {
        path: "/",
        redirect: '/admin',
    },

    {
        path: '/admin',

        name: '管理员管理',
        component: SpaceFrame,
        hidden: true,
        children: [{
                path: 'branch',
                component: BranchFrame,
                hidden: true,

            },
            {
                path: 'user',
                hidden: true,
                component: UserFrame,

            },
            {
                path: '/updateuser/:id',
                hidden: true,
                component: UpdateuserFrame,

            }
        ]
    }


]

//4.创建Vue-router实例并传递`routes`配置
//你可以在这里输入更多的配置
//暂时保持简单
const router = createRouter({
    //5.内部提供了history模式的实现，为了简单起见，我们在这里使用hash模式
    history: createWebHashHistory(),
    routes, //传入routes数组，这一行是简写，是routes:routes的缩写
})

//6.默认导出创建好的Vue-router的实例
export default router;