//导入axios
import axios from 'axios'

var baseURL = "https://nuoche.yingmei.club:4443/oasystem/bhwl";
//var baseURL = "http://localhost:8080/noteshopping";
//创建axios实例
const axiosInstance = axios.create({
    baseURL: baseURL, //URL地址
    timeout: 500000 // 请求超时时间
})


//定义拦截器
//添加请求拦截器 
axiosInstance.interceptors.request.use(function(config) {
    return config;
}, function(error) {
    return Promise.reject(error);
});
//默认导出
export { axiosInstance, baseURL };