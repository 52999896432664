<template>
  <div class="mt-5">
    <el-form
      label-width="5rem"
      :model="Addbranch"
      style="
        max-width: 20rem;
        height: 30rem;
        background-color: LightCyan;
        border-radius: 5%;
      "
      class="mx-auto my-auto mt-3"
    >
      <el-form-item> </el-form-item>
      <el-form-item label="状态:">
        <el-radio-group v-model="person.userstatus">
          <el-radio label="1" border>通过审核</el-radio>
          <el-radio label="0" border>待审核</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="用户名:">
        <el-input v-model="person.username" style="width: 16rem" />
      </el-form-item>
      <el-form-item label="密码:">
        <el-input v-model="person.password" style="width: 16rem" />
      </el-form-item>
      <el-form-item label="手机号码:">
        <el-input v-model="person.tel" style="width: 16rem" />
      </el-form-item>
      <el-form-item label="部门:" style="margin-top: 16px">
        <el-select
          v-model="person.branchid"
          placeholder="部门选择"
          size="large"
        >
          <el-option
            v-for="item in personclass"
            :key="item.id"
            :label="item.branchname"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" class="mx-5" @click="updatebranch"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "AddrenwuFrame",
  components: {},
  data() {
    return {
      personclass: [],
      person: {
        id: this.$route.params.id,
        branchid: null,
        username: null,
        tel: null,
        password: null,
        userstatus: null,
      },
    };
  },
  methods: {
    oneuser() {
      let url = "/user/onestaffuserlist?id=" + this.person.id;
      this.$axios.get(url).then((response) => {
        let data = response.data;
        if (data.code == 200) {
          this.person = data.data;
          console.log(this.person);
        } else {
          alert(data.msg);
        }
      });
    },
    updatebranch() {
      console.log(this.person);
      this.$axios.post("/user/audituser", this.person).then((response) => {
        let data = response.data;
        if (data.code == 200) {
          this.$router.push("/admin/user");
        } else {
          alert(data.msg);
        }
      });
    },
    classify() {
      this.$axios.get("/user/branchlist").then((response) => {
        let data = response.data;
        if (data.code == 200) {
          this.personclass = data.data;
        } else {
          alert(data.msg);
        }
      });
    }
  },
  mounted() {
    this.classify();
    this.oneuser();
  },
};
</script>

<style scoped>
.headImg {
  width: 5rem;
  height: 5rem;
  border-radius: 10%;
}
</style>