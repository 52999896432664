<template>
  <div class="mt-2">
    <el-input v-model="username" size="" placeholder="请输入用户名搜索">
      <template #append>
        <el-button type="info" plain @click="userlist">
          <el-icon style="font-size: 1rem"><Search /></el-icon>
        </el-button>
      </template>
    </el-input>
    <el-table class="table table-hover" :data="userHome" height="580">
      <el-table-column label="头像">
        <template #default="scope">
          <el-image
            :src="scope.row.tempFilePaths"
            alt=""
            style="width: 60px; height: 60px"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="username" label="用户名"></el-table-column>
      <!--
        <el-table-column
        prop="openid"
        label="用户id"
        width="300"
      ></el-table-column>
      -->
      <el-table-column prop="branchname" label="部门"></el-table-column>
      <!--
      <el-table-column label="状态">
        <template #default="scope">
          <el-tag :type="scope.row.userstatus == '0' ? 'danger' : 'primary'">
            {{ scope.row.userstatus == 0 ? "未审核" : "已审核" }}</el-tag
          >
        </template>
      </el-table-column>
      -->
      <el-table-column label="修改/审核用户">
        <template #default="scope">
          <el-button :disabled="scope.row.id == 9" color="rgb(236, 253, 253)"
            ><el-link class="a;router-link-active">
              <router-link :to="{ path: '/updateuser/' + scope.row.id }"
                >修改
              </router-link>
            </el-link></el-button
          >
        </template>
      </el-table-column>
      <!--
      <el-table-column label="用户删除">
        <template #default="scope">
          <el-button
            size="small"
            type="danger"
            @click="handleDelete(scope.row.id)"
            >删除用户</el-button
          >
        </template>
      </el-table-column>
      -->
    </el-table>
  </div>
</template>
<script>
import { Search } from "@element-plus/icons-vue";
export default {
  name: "UserFrame",
  components: {
    Search,
  },
  data() {
    return {
      username: null,
      userHome: [],
    };
  },
  methods: {
    userlist() {
      let url = "/user/staffuserlist?username=" + this.username;
      this.$axios.get(url).then((response) => {
        let data = response.data;
        if (data.code == 200) {
          this.userHome = data.data;
        } else {
          alert(data.msg);
        }
      });
    },
    handleDelete(id) {
      let url = "/user/delectuser?id=" + id;
      this.$axios.get(url).then((response) => {
        let data = response.data;
        if (data.code == 200) {
          alert(data.msg);
        } else {
          alert(data.msg);
        }
      });
    },
    updatestatus(id) {
      let url = "/user/audituser?id=" + id;
      this.$axios.get(url).then((response) => {
        let data = response.data;
        if (data.code == 200) {
          alert(data.msg);
        } else {
          alert(data.msg);
        }
      });
    },
  },
  mounted() {
    this.userlist();
  },
  watch: {
    "handleDelete(id)": {
      handler() {
        this.userlist();
      },
    },
  },
};
</script>

<style scoped>
  .headImg{
    width:5rem;
    height:5rem;
    border-radius: 10%;
  }
  a {
  text-decoration: none;
}

.router-link-active {
  text-decoration: none;
}
</style>