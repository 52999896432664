<template>
  <el-affix>
    <el-row style="height: 60px">
      <el-col :span="6" style="background-color: rgb(104, 104, 104)"
        ><div class="fontpp">Ar-项目管理</div></el-col
      >
      <el-col :span="18" class="text-right">
        <el-menu
          mode="horizontal"
          class="h-100 border-0"
          style="background-color: rgb(104, 104, 104)"
          text-color="#fff"
          active-text-color="#ffd04b"
          background-color="#545c64"
          :router="true"
        >
          <el-menu-item index="/" class="ms-auto">退出登录</el-menu-item>
          <el-menu-item> </el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
    <el-row style="background-color: rgb(104, 104, 104)">
    </el-row>
  </el-affix>
</template>

<script>
export default {
  name: "SpaceHeader",
  components: {},
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.router-link-active {
  text-decoration: none;
}
.fontpp {
  font-family: "默陌月芽体";
  font-size: 15px;
  color: rgb(89, 236, 150);
}
</style>