<template>
  <SpaceHeader />
  <el-row>
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      :ellipsis="false"
      :router="true"
    >
      <el-menu-item index="/admin/user">用户管理</el-menu-item>
      <el-menu-item index="/admin/branch">部门管理</el-menu-item>
    </el-menu>
  </el-row>

  <el-row style="height: 85vh">
    <el-col :span="24" style="background-color: rgb(250, 250, 250)">
      <router-view></router-view>
    </el-col>
  </el-row>
</template>


<script>
import SpaceHeader from "./SpaceHeader.vue";

export default {
  name: "SpaceFrame",
  components: {
    SpaceHeader,
  },
};
</script>

<style>
</style>